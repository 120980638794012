import { useState } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || "en");

  const handleChange = (event) => {
    const newLang = event.target.value;
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  return (
    <Box display="flex" alignItems="center" sx={{ paddingX: "5px" }}>
      <LanguageIcon color="primary" fontSize="medium" sx={{ marginRight: 1 }} />
      <Select
        value={language}
        onChange={handleChange}
        variant="standard"
        sx={{ color: "white" }}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="es">Español</MenuItem>
      </Select>
    </Box>
  );
};

export default LanguageSwitcher;
