import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CircularProgress,
  Box,
  Typography,
  ThemeProvider,
  Container,
} from "@mui/material";
import { format, toZonedTime } from "date-fns-tz";
import "../styles/fonts.css";
import theme from "../styles/theme";
import { useTranslation } from "react-i18next";
import { NavigationBar, AnnouncementBar } from "../components/NavigationBar.js";
import CountdownTimer from "../components/CountdownTimer.js";
import SearchBar from "../components/SearchBar.js";
import PlayerTransferTable from "../components/TransferPlayerTable.js";
import Footer from "../components/Footer.js";
import TransferPlayer from "../datamodels/TransferPlayer.js";
import SingleAnswerGuessBar from "../components/SingleAnswerGuessBar.js";
import ResultModal from "../components/ResultModal";
const { transliterate } = require("transliteration");

export const MAX_GUESS_COUNT = 7;
const seedrandom = require("seedrandom");
// Function to get today's date in PDT as a string
function getTodaysDateInSF() {
  // San Francisco, California timezone
  const timezone = "America/Los_Angeles";
  // Get the current date/time in the specific timezone
  const nowInSF = toZonedTime(new Date().toISOString(), timezone);
  // Format as 'YYYY-MM-DD'
  return format(nowInSF, "yyyy-MM-dd", { timeZone: timezone });
}

export const TransferOfTheDay = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [transferOfDayPlayerData, setTransferOfDayPlayerData] = useState([]);
  const [todayPlayerData, setTodayPlayerData] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [guessedPlayer, setGuessedPlayer] = useState([]);
  const [guessState, setGuessState] = useState("");
  const [guessCount, setGuessCount] = useState(0); // Initial guess count
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const location = useLocation();
  const showRandomGame = location.pathname.endsWith("random");
  const { t } = useTranslation();

  // Function to handle player selection from SearchBar
  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
  };

  const findTransferOfDay = (transferOfDayPlayerData) => {
    // Use today's date in PDT as the seed
    const seed = getTodaysDateInSF();
    const rng = seedrandom(seed);
    let todayIndex = Math.floor(rng() * transferOfDayPlayerData.length);
    if (showRandomGame) {
      todayIndex = Math.floor(Math.random() * transferOfDayPlayerData.length);
    }
    const foundPlayer = transferOfDayPlayerData[todayIndex];
    console.log(
      "Found the transfer of the day player #" +
        (todayIndex + 1) +
        " with seed " +
        seed
    );
    if (foundPlayer !== undefined || foundPlayer !== null)
      setTodayPlayerData(foundPlayer);
  };

  // Function to handle the submission of the guess
  const handleSubmit = () => {
    if (selectedPlayer) {
      if (
        guessedPlayer.some((player) => player.label === selectedPlayer.label)
      ) {
        setGuessState("PlayerAlreadyGuessed");
      } else {
        const isCorrectGuess =
          selectedPlayer.label === transliterate(todayPlayerData.playerName);
        setGuessState(isCorrectGuess ? "CorrectGuess" : "WrongGuess");
        setGuessCount((prevCount) => prevCount + 1); // Increase the guess count by 1
        console.log("incrementing guess count to:", guessCount);
        if (isCorrectGuess) {
          // set guess count to 10 + the current count to reveal all player data
          setGuessCount((prevCount) => prevCount + 10);
          setModalMessage(t("congratulationsGuesses") + (guessCount + 1));
          setIsModalOpen(true);
        } else {
          setGuessedPlayer([...guessedPlayer, selectedPlayer]);
          if (guessCount + 1 >= MAX_GUESS_COUNT) {
            setModalMessage(t("betterLuckNextTime") + todayPlayerData.playerName);
            setIsModalOpen(true);
          }
        }
      }
    } else {
      setGuessState("NoPlayerSelected");
    }
    // Clear the selection from search bar
    setSelectedPlayer(null);
  };

  const handleReveal = () => {
    setGuessCount((prevCount) => prevCount + 1); // Increase the guess count by 1
    setGuessState("FieldRevealed");
  };

  const readCsv = async (url) => {
    const res = await fetch(url);
    const csv = await res.text();
    return await new Promise((resolve, reject) => {
      Papa.parse(csv, {
        header: true,
        complete: function (results) {
          resolve(results.data); // Resolve the promise with the parsed data
        },
        error: (error) => {
          console.error("Error while parsing CSV file:", error);
          reject(error); // Reject the promise on error
        },
      });
    });
  };

  useEffect(() => {
    const TansferPlayerOfDayCsvFileUrl = "/tables/Transfer_of_the_day.csv";

    const fetchTransferPlayerData = async () => {
      try {
        let csvData = await readCsv(TansferPlayerOfDayCsvFileUrl);
        // Convert your players array to an options array for react-select
        const allTransferOfDayPlayers = csvData
          .map((player) => TransferPlayer.createFromObject(player))
          .filter((player) => player.playerName !== "N/A");
        setTransferOfDayPlayerData(allTransferOfDayPlayers);
        findTransferOfDay(allTransferOfDayPlayers);
        setIsLoading(false); // Set loading to false after data is loaded
      } catch (error) {
        console.error("Error fetching CSV data:", error);
        // setIsLoading(false);
      }
    };

    fetchTransferPlayerData();
  }, []);

  // Logging the updated state
  useEffect(() => {
    if (transferOfDayPlayerData.length > 0) {
      console.log("Parsing complete for all player data");
      console.log("Parsing complete for today's player data");
    }
  }, [transferOfDayPlayerData]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="100%" disableGutters="true">
        <NavigationBar />
        <AnnouncementBar />
        <Container maxWidth="xl" sx={{ minHeight: "100vh" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              alignItems: "center",
            }}>
            <Typography
              variant="h1"
              sx={{
                padding: 2,
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "50px",
              }}>
              {t("transferOfTheDay")}
            </Typography>
            <CountdownTimer isFloating={true} />
          </Box>
          <Typography
            variant="h2"
            sx={{
              padding: 2,
              textAlign: "center",
              fontSize: "25px",
            }}>
            {t("transferOfTheDayHeadline")}
          </Typography>
          {/* Additional components */}
          <Box>
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <SearchBar
                  players={transferOfDayPlayerData}
                  selectedPlayer={selectedPlayer}
                  onPlayerSelect={handlePlayerSelect}
                />
                <SingleAnswerGuessBar
                  guessesUsed={guessCount}
                  guessState={guessState}
                  handleSubmit={handleSubmit}
                />
                <PlayerTransferTable
                  todayPlayerData={todayPlayerData}
                  guessCount={guessCount}
                  guessedPlayersData={guessedPlayer}
                  handleReveal={handleReveal}
                />
              </Box>
            )}
          </Box>
        </Container>
        <Footer />
      </Container>

      {/* Result Modal */}
      <ResultModal
        open={isModalOpen}
        message={modalMessage}
        onClose={() => setIsModalOpen(false)}
      />
    </ThemeProvider>
  );
};

export default TransferOfTheDay;
