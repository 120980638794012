import React, { useState } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { PlayerPortrait, PlayerNationality } from "./PlayerDetail";
import { useTranslation } from "react-i18next";
const { transliterate } = require("transliteration");

const SearchBar = ({ players, selectedPlayer, onPlayerSelect }) => {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  // Convert your players array to an options array for Material UI Autocomplete
  const playerOptions = players.map((player) => ({
    label: transliterate(player.playerName), // Use transliterated name for matching and displaying
    player: player, // Keep the original player object for use in customOption
  }));

  // Filter and limit options based on input value
  const filteredPlayerOptions = inputValue
    ? playerOptions
        .filter((option) =>
          option.label
            .toLowerCase()
            .includes(transliterate(inputValue).toLowerCase())
        )
        .sort((a, b) => {
          // Sorting logic remains the same
          if (b.player.numericFee - a.player.numericFee !== 0) {
            return b.player.numericFee - a.player.numericFee;
          }
          return a.label.localeCompare(b.label);
        })
        .filter(
          (function () {
            const seen = new Set();
            return function (option) {
              if (seen.has(option.label)) {
                return false;
              }
              seen.add(option.label);
              return true;
            };
          })()
        )
        .slice(0, 15)
    : playerOptions
        .sort((a, b) => {
          // Sorting logic remains the same
          if (b.player.numericFee - a.player.numericFee !== 0) {
            return b.player.numericFee - a.player.numericFee;
          }
          return a.label.localeCompare(b.label);
        })
        .filter(
          (function () {
            const seen = new Set();
            return function (option) {
              if (seen.has(option.label)) {
                return false;
              }
              seen.add(option.label);
              return true;
            };
          })()
        )
        .slice(0, 15);

  return (
    <Autocomplete
      value={selectedPlayer}
      onChange={(event, newValue) => {
        onPlayerSelect(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={filteredPlayerOptions}
      getOptionLabel={(option) => option.label || ""}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <PlayerPortrait playerData={option.player} />
          <Box sx={{ paddingRight: "10px" }}></Box>
          <PlayerNationality playerData={option.player.nationality} />
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("searchPlaceholder")}
          variant="outlined"
          id="searchInput"
        />
      )}
      noOptionsText={inputValue ? t("noPlayerFound") : null}
    />
  );
};

export default SearchBar;
